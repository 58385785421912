<template>
    <div>
        <div class="lg:px-18 sm:px-18 mt-3 items-center">
            <h3 class="text-left md:ml-3 text-24 font-extrabold text-corporate-100 leading-9">Getting Started With CredPal !</h3>
        </div>
        <div class="mt-5 lg:px-4 sm:px-4 flex flex-col">
            <div class="flex flex-row">
                <div class="w-12">
                    <div class="rounded-full w-33px h-33px bg-corporate-200 flex items-center justify-center">
                        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.7433 8.88194C16.6364 8.88194 17.3605 8.1545 17.3605 7.25806C17.3605 6.26795 17.3605 6.26795 17.3605 6.26795V4.36004C17.3605 3.46966 16.6324 2.75 15.74 2.75L11.5083 2.75C10.5721 2.75 9.81348 3.50395 9.81348 4.43433L9.81348 7.27547C9.81348 8.16264 10.5369 8.88194 11.43 8.88194H15.7433Z" fill="#E8ECFF" />
                            <path d="M14.0345 10.3125C15.3363 10.3125 16.3916 9.25223 16.3916 7.94563C16.3916 6.50252 16.3916 6.50252 16.3916 6.50252V3.72169C16.3916 2.42393 15.3304 1.375 14.0297 1.375L7.86188 1.375C6.49736 1.375 5.3916 2.4739 5.3916 3.82996L5.3916 7.97102C5.3916 9.26409 6.44602 10.3125 7.74769 10.3125H14.0345Z" fill="#CCD6FF" />
                            <path d="M12.2812 11.6875C13.9899 11.6875 15.375 10.301 15.375 8.59237C15.375 6.70522 15.375 6.70522 15.375 6.70522V3.06874C15.375 1.37168 13.9821 3.98424e-07 12.2751 3.23806e-07L4.17974 0C2.3888 -7.82844e-08 0.9375 1.43703 0.9375 3.21034L0.9375 8.62556C0.9375 10.3165 2.32143 11.6875 4.02987 11.6875H12.2812Z" fill="#1F1F57" />
                            <path d="M10.2329 9.36275C10.5363 9.53823 10.8885 9.63867 11.2642 9.63867C12.4032 9.63867 13.3267 8.71526 13.3267 7.57617C13.3267 6.43708 12.4032 5.51367 11.2642 5.51367C10.8885 5.51367 10.5363 5.61411 10.2329 5.7896C10.4947 5.94105 10.7202 6.1484 10.8929 6.39533C11.0101 6.35852 11.1348 6.33867 11.2642 6.33867C11.9476 6.33867 12.5017 6.89272 12.5017 7.57617C12.5017 8.25962 11.9476 8.81367 11.2642 8.81367C11.1348 8.81367 11.0101 8.79383 10.8929 8.75701C10.7202 9.00394 10.4947 9.21129 10.2329 9.36275Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.20166 8.81367C9.88511 8.81367 10.4392 8.25962 10.4392 7.57617C10.4392 6.89272 9.88511 6.33867 9.20166 6.33867C8.51821 6.33867 7.96416 6.89272 7.96416 7.57617C7.96416 8.25962 8.51821 8.81367 9.20166 8.81367ZM9.20166 9.63867C10.3407 9.63867 11.2642 8.71526 11.2642 7.57617C11.2642 6.43708 10.3407 5.51367 9.20166 5.51367C8.06257 5.51367 7.13916 6.43708 7.13916 7.57617C7.13916 8.71526 8.06257 9.63867 9.20166 9.63867Z" fill="white" />
                            <rect x="3.35791" y="2.41992" width="3.4375" height="1.03125" rx="0.515625" fill="white" />
                        </svg>
                    </div>
                </div>
                <div class="flex flex-col ml-5 mt-1">
                    <div class="w-full md:w-420px">
                        <h5 class="text-corporate-300 text-12 font-bold">CredPal Credit Card</h5>
                        <p class="text-11 md:text-12 text-corporate-300 mt-2 font-thin">Make important purchases and pay bills across any channel online & offline. Get up to 5% cashback across partnered CredPal merchants.</p>
                    </div>
                    <div class="md:w-460px p-4 flex flex-row rounded-sm bg-corporate-400 mt-4">
                        <div class="w-12 pt-3">
                            <div class="rounded-full w-6 h-6 bg-corporate-500 flex items-center justify-center mr-3">
                                <svg width="3" height="10" viewBox="0 0 3 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.218 9.99739C0.881999 9.99739 0.592666 9.882 0.35 9.65122C0.116667 9.41121 0 9.12504 0 8.79272C0 8.4604 0.116667 8.17884 0.35 7.94806C0.592666 7.71728 0.881999 7.60189 1.218 7.60189C1.554 7.60189 1.83866 7.71728 2.072 7.94806C2.30533 8.17884 2.422 8.4604 2.422 8.79272C2.422 9.12504 2.30533 9.41121 2.072 9.65122C1.83866 9.882 1.554 9.99739 1.218 9.99739ZM0.266 2.85244V0H2.156V2.85244L1.96 6.508H0.461999L0.266 2.85244Z" fill="#1F1F57" />
                                </svg>
                            </div>
                        </div>
                        <p class="text-corporate-300 text-10 text-left leading-loose">Please note we’re currenty experiencing technical challenges with issuing cards and there will be a delay in delivering your card. Kindly enjoy the transfer feature while we work on getting your card to you.</p>
                    </div>
                </div>
            </div>
            <div class="flex flex-row mt-6">
                <div class="w-12">
                    <div class="rounded-full w-33px h-33px bg-corporate-200 flex items-center justify-center">
                        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4" d="M3.20374 6.78125C2.85234 6.78125 2.56788 7.07196 2.56788 7.43108L2.39258 10.5703C2.39258 11.0279 2.75593 11.3984 3.20374 11.3984C3.65155 11.3984 4.01411 11.0279 4.01411 10.5703L3.8396 7.43108C3.8396 7.07196 3.55514 6.78125 3.20374 6.78125Z" fill="#1F1F57" />
                            <path d="M4.07174 0.461715C4.07174 0.461715 3.88927 0.272795 3.77612 0.190549C3.61198 0.0635164 3.40958 0 3.20799 0C2.98169 0 2.77133 0.0716595 2.60002 0.206835C2.56894 0.238593 2.43667 0.358297 2.32751 0.469858C1.64224 1.12212 0.521119 2.82485 0.178487 3.71652C0.124304 3.8517 0.00717136 4.19371 0 4.37693C0 4.55119 0.0382473 4.71894 0.116335 4.87773C0.2255 5.07642 0.396815 5.23603 0.599207 5.32316C0.739447 5.37935 1.15937 5.46648 1.16734 5.46648C1.6271 5.55442 2.37452 5.60165 3.20002 5.60165C3.98648 5.60165 4.70282 5.55442 5.16976 5.48276C5.17772 5.47462 5.69884 5.38749 5.87813 5.2914C6.20482 5.11632 6.40801 4.77431 6.40801 4.40869V4.37693C6.40004 4.13834 6.19686 3.63672 6.18968 3.63672C5.84705 2.79309 4.78011 1.13027 4.07174 0.461715Z" fill="#1F1F57" />
                            <path opacity="0.4" d="M10.4137 5.55869C10.7651 5.55869 11.0496 5.26797 11.0496 4.90885L11.2241 1.76958C11.2241 1.31193 10.8615 0.941406 10.4137 0.941406C9.96589 0.941406 9.60254 1.31193 9.60254 1.76958L9.77784 4.90885C9.77784 5.26797 10.0623 5.55869 10.4137 5.55869Z" fill="#1F1F57" />
                            <path d="M13.5007 7.46418C13.3915 7.26548 13.2202 7.10668 13.0178 7.01874C12.8775 6.96255 12.4568 6.87541 12.4497 6.87541C11.9899 6.78747 11.2425 6.74023 10.417 6.74023C9.63051 6.74023 8.91418 6.78747 8.44724 6.85913C8.43927 6.86727 7.91815 6.95522 7.73887 7.0505C7.41138 7.22558 7.20898 7.5676 7.20898 7.93405V7.96581C7.21695 8.20441 7.41934 8.70522 7.42731 8.70522C7.76994 9.54887 8.83609 11.2126 9.54526 11.8803C9.54526 11.8803 9.72773 12.0692 9.84087 12.1507C10.0042 12.2785 10.2066 12.342 10.4098 12.342C10.6353 12.342 10.8449 12.2704 11.017 12.1352C11.0481 12.1034 11.1803 11.9837 11.2895 11.873C11.974 11.2199 13.0959 9.51711 13.4377 8.62623C13.4927 8.49105 13.6098 8.14822 13.617 7.96581C13.617 7.79073 13.5787 7.62297 13.5007 7.46418Z" fill="#1F1F57" />
                        </svg>
                    </div>
                </div>
                <div class="flex flex-col ml-5 mt-1">
                    <div class="w-full md:w-420px">
                        <h5 class="text-corporate-300 text-12 font-bold">Transfers</h5>
                        <p class="text-11 md:text-12 text-corporate-300 mt-2 font-thin">Need to pay for goods, services or get quick cash? Use the transfer feature to move money from your credit card to your personal bank account.</p>
                    </div>
                </div>
            </div>
            <div class="flex flex-row mt-6">
                <div class="w-12">
                    <div class="rounded-full w-33px h-33px bg-corporate-200 flex items-center justify-center">
                        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0926 6.17112C12.0926 6.72886 12.5474 7.18266 13.1064 7.18266C13.3883 7.18266 13.617 7.41091 13.617 7.69216V9.52706C13.617 11.0787 12.352 12.3409 10.7969 12.3409H2.82077C1.2657 12.3409 0 11.0787 0 9.52706V7.69216C0 7.41091 0.228766 7.18266 0.510638 7.18266C1.0703 7.18266 1.52511 6.72886 1.52511 6.17112C1.52511 5.62765 1.08868 5.21869 0.510638 5.21869C0.375149 5.21869 0.245787 5.16502 0.149787 5.06923C0.0537872 4.97344 0 4.84369 0 4.70918L0.0013617 2.8145C0.0013617 1.26289 1.26638 0 2.82145 0H10.7956C12.3506 0 13.6163 1.26289 13.6163 2.8145L13.617 4.65008C13.617 4.78459 13.5632 4.91502 13.4679 5.01013C13.3719 5.10592 13.2426 5.15958 13.1064 5.15958C12.5474 5.15958 12.0926 5.61338 12.0926 6.17112ZM8.34185 6.61606L9.14457 5.83618C9.28414 5.70167 9.33248 5.5033 9.27189 5.31988C9.21197 5.13646 9.05538 5.00603 8.8661 4.97953L7.75699 4.81785L7.26065 3.81514C7.17555 3.64259 7.00261 3.53526 6.80993 3.53458H6.80857C6.61657 3.53458 6.44363 3.64191 6.35717 3.81446L5.86083 4.81785L4.75376 4.97885C4.56244 5.00603 4.40585 5.13646 4.34525 5.31988C4.28534 5.5033 4.33368 5.70167 4.47257 5.83618L5.27529 6.61606L5.08602 7.71863C5.05334 7.90885 5.13027 8.09771 5.28687 8.21116C5.37538 8.27433 5.47819 8.30694 5.58236 8.30694C5.66202 8.30694 5.74236 8.28724 5.81589 8.24852L6.80857 7.72815L7.79921 8.24716C7.97078 8.33887 8.17436 8.32461 8.33027 8.21048C8.48755 8.09771 8.56448 7.90885 8.5318 7.71863L8.34185 6.61606Z" fill="#1F1F57" />
                        </svg>
                    </div>
                </div>
                <div class="flex flex-col ml-5 mt-1">
                    <div class="w-full md:w-420px">
                        <h5 class="text-corporate-300 text-12 font-bold">CredPal Standard Loan</h5>
                        <p class="text-11 md:text-12 text-corporate-300 mt-2 font-thin">Need a large sum of money of up to 5million Naira, and would love to spread payment over time? Simply use the request loan feature!</p>
                    </div>
                </div>
            </div>
            <div class="flex flex-row mt-6">
                <div class="w-12">
                    <div class="rounded-full w-33px h-33px bg-corporate-200 flex items-center justify-center">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.97613 5.05522C3.9865 7.035 4.92514 7.39262 5.57363 10.5599C4.9373 10.7738 4.47781 11.3765 4.47781 12.0848C4.47781 12.5006 4.63636 12.8799 4.89582 13.1656C3.96024 14.1959 4.69381 15.8555 6.08515 15.8555H6.21629C6.46168 17.0771 7.54203 18 8.83367 18H9.16765C10.4593 18 11.5396 17.0771 11.785 15.8555H11.9162C13.3073 15.8555 14.0412 14.196 13.1055 13.1656C13.8843 12.308 13.5263 10.9307 12.4291 10.5602C12.6696 9.38644 13.0471 8.24047 13.553 7.15959C14.4225 5.30117 14.0985 3.00817 12.5409 1.46074C9.38523 -1.67517 3.95332 0.56821 3.97613 5.05522ZM9.16761 16.9445H8.83363C8.12769 16.9445 7.52582 16.4885 7.3068 15.8555H10.6944C10.4754 16.4885 9.87355 16.9445 9.16761 16.9445ZM11.9161 14.8C11.7648 14.8 6.25137 14.8 6.08515 14.8C5.78035 14.8 5.5325 14.5519 5.5325 14.2469C5.5325 13.9417 5.78081 13.6934 6.08515 13.6934H11.9161C12.2204 13.6934 12.4688 13.9417 12.4688 14.2469C12.4687 14.5519 12.2209 14.8 11.9161 14.8ZM12.4687 12.0848C12.4687 12.3907 12.2196 12.6379 11.9161 12.6379C11.6705 12.6379 6.33068 12.6379 6.08512 12.6379C5.77905 12.6379 5.53246 12.3885 5.53246 12.0848C5.53246 11.7801 5.78014 11.5317 6.08512 11.5317H11.9161C12.2206 11.5317 12.4687 11.7795 12.4687 12.0848ZM8.47328 5.38948H8.10414C7.90058 5.38948 7.735 5.22376 7.735 5.02004V4.78078C7.735 4.57706 7.90058 4.41134 8.10414 4.41134C8.30769 4.41134 8.47328 4.57706 8.47328 4.78078V5.38948ZM11.3702 10.4761H9.52797V6.44502H10.7584C11.0495 6.44502 11.2858 6.20893 11.2858 5.91725C11.2858 5.62592 11.0495 5.38948 10.7584 5.38948H9.52797V4.78078C9.52797 3.99511 8.88918 3.3558 8.10414 3.3558C7.3191 3.3558 6.68031 3.99511 6.68031 4.78078V5.02004C6.68031 5.80572 7.3191 6.44502 8.10414 6.44502H8.47328V10.4761H6.6318C5.9723 7.11512 5.04006 6.76042 5.03078 5.04995C5.01257 1.50532 9.30504 -0.268414 11.7977 2.20983C12.5539 2.96138 12.9705 3.96238 12.9705 5.02884C12.9704 6.74663 12.0341 7.10411 11.3702 10.4761Z" fill="#1F1F57" />
                            <path d="M1.49377 7.57854L0.227089 8.45682C-0.0123247 8.62282 -0.0719496 8.95159 0.0939174 9.1912C0.25989 9.43095 0.588495 9.49034 0.827698 9.32448L2.09438 8.4462C2.33379 8.28019 2.39342 7.95143 2.22755 7.71182C2.06172 7.47225 1.73322 7.41261 1.49377 7.57854Z" fill="#1F1F57" />
                            <path d="M2.09437 3.22647L0.827688 2.34819C0.588275 2.18226 0.25981 2.24193 0.0939079 2.48147C-0.0719592 2.72108 -0.012299 3.04984 0.22708 3.21584L1.49376 4.09413C1.73307 4.26009 2.06171 4.20042 2.22754 3.96085C2.39341 3.72124 2.33378 3.39244 2.09437 3.22647Z" fill="#1F1F57" />
                            <path d="M16.2064 4.18815C16.4255 4.18815 16.4046 4.16465 17.7729 3.21589C18.0123 3.04989 18.0719 2.72112 17.9061 2.48151C17.7402 2.24187 17.4116 2.18226 17.1723 2.34823L15.9056 3.22651C15.481 3.52094 15.6922 4.18815 16.2064 4.18815Z" fill="#1F1F57" />
                            <path d="M17.7729 8.45687L16.5062 7.57858C16.2669 7.41255 15.9383 7.47229 15.7725 7.71187C15.6066 7.95147 15.6663 8.28024 15.9056 8.44624L17.1723 9.32452C17.4118 9.49053 17.7402 9.43078 17.9061 9.19124C18.072 8.95167 18.0123 8.62287 17.7729 8.45687Z" fill="#1F1F57" />
                        </svg>
                    </div>
                </div>
                <div class="flex flex-col ml-5 mt-1">
                    <div class="w-full md:w-420px">
                        <h5 class="text-corporate-300 text-12 font-bold">Bills & Settlement</h5>
                        <p class="text-11 md:text-12 text-corporate-300 mt-2 font-thin">Top up your phone, pay for your DSTV & electricity bills, renew your internet subscription, and many more! All on your dashboard with your CredPal credit card. </p>
                    </div>
                </div>
            </div>
            <div class="flex justify-center mb-8 mt-8">
                <div class="flex flex-col">
                    <button type="button" class="px-32 py-4 bg-corporate-600 text-sm lg:text-14 font-semibold px-16 rounded-sm text-black focus:outline-none" @click.prevent="$emit('close')">
                        <span class="font-thin">Close</span>
                    </button>
                    <div class="h-1 bg-corporate-700 duration-300 animate-pulse" :class="interval == 0 ? 'w-1' : `w-${interval}`"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            interval: 48,
        }
    }
}
</script>